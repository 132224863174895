<template>
  <div id="nav" :style="{ zoom: `${zoom}%` }">
    <div class="color_box">
      <div class="header topnav">
        <img src="./img/Logo.png" alt="" />
        <div class="right">
          <!-- <el-dropdown>
            <span class="el-dropdown-link" style="color: #fff; font-size: 13px">
              {{ $store.state.isLogin.loginStore.first_name
              }}<i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="outlogin" style="font-size: 13px">
                  退出
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </div>
    </div>
    <div class="bg2">
      <div class="menu container">
        <div
          class="item"
          v-for="item in nav"
          :key="item.name"
          @click="
            product_permission.includes(item.product_permission)
              ? item.fn(item.jump_path)
              : open_permission(item.product_permission, item.name)
          "
        >
          <!-- <div class="item" v-for="item in nav" :key="item.name" @click="product_router(item)"> -->
          <img class="img" :src="item.icon" />
          <div class="right">
            <div class="title">
              <div>{{ item.name }}</div>
            </div>
            <div class="desc">{{ item.title }}</div>
            <div class="desc1">
              {{ item.desc }}
            </div>
          </div>
          <div class="footer">
            <div class="quetely">立即使用</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get_url, products } from "@/api/nav.js";
import product_info from "@/store/product_info.js";

export default {
  data() {
    return {
      zoom: 100,
      url: "",
      tax: "",
      menu_logo: product_info.nav_logo,
      nav: [],
      open_name: "税务分析系统",
    };
  },
  beforeCreate() {
    this.$store.commit("setShowNav", false);
  },
  destroyed() {
    if (this.$route.name != "Login") this.$store.commit("setShowNav", true);
  },
  created() {
    this.fetchData();
    this.set_zoom();
    this.set_nav();
    this.$store.commit("setShowNav", false);
  },
  computed: {
    product_permission() {
      return this.$store.state.isLogin.loginStore["product_permission"];
    },
  },
  methods: {
    set_nav() {
      this.nav = [
        {
          name: product_info.nickname,
          title: "识别财报数据隐藏风险",
          desc:
            "提供先进的企业经营数据分析的AI工具和平台。帮助金融机构、政府监管和企业洞察目标公司真实的运营情况，实现风险控制、市场营销和内部管控智能化。 ",
          icon: require("./img/caipan.png"),
          jump_path: "/Rou",
          product_permission: 1,
          fn: this.jump_now,
        },
        {
          name: "数行家 · 流水尽调",
          title: "人工智能赋能流水分析",
          desc:
            "支持50+家主流银行流水，解析流水关键信息。对审核出来的流水进行平衡计算，以及利用多套规则，判断数据的真实性和完整性。智能识别企业主体，智能分类全部交易，分析收入的收支构成，真实反应企业经营、投融资情况。",
          icon: require("./img/liushui.png"),
          jump_path: "/menu",
          product_permission: 2,
          fn: this.jump,
        },
        {
          name: "数行家 · 税务系统",
          title: "风控场景下的税务分析",
          desc:
            "与企业财务数据形成更深层的交叉比对，提升财务分析质量。基于税务数据进行深度分析，挖掘更多的企业经营数据。",
          icon: require("./img/shuiwu.png"),
          jump_path: "/menu",
          product_permission: 3,
          fn: this.taxJump,
        },
      ];
    },
    set_zoom() {
      var percentage,
        appHeight = 1900; //设置默认屏幕大小
      percentage = (window.innerWidth * 100) / appHeight;
      percentage = Math.floor(percentage);
      this.zoom = percentage;
    },
    fetchData() {
      get_url().then(({ data }) => {
        this.url = data.bsdd;
        this.tax = data.tax;
      });
    },

    open_permission(product_permission, open_name) {
      this.$confirm(
        `您尚未开通${open_name}系统的权限，您可以先申请开通权限`,
        "提示",
        {
          confirmButtonText: "确定申请",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "prompt",
        }
      )
        .then(() => {
          products(product_permission).then(({ data }) => {
            this.$message.info(data.msg);
          });
        })
        .catch(() => {});
    },
    product_router(item) {
      if (item.product_permission == 1) {
        // this.$router.push(item.jump_path)
        this.jump_now(item.jump_path);
        // var url = `${location.origin}/#` + item.jump_path;
        // window.open(url);
      } else if (item.product_permission == 2) {
        this.jump();
      } else {
        return this.$message({
          message: "暂未开放！",
          type: "warning",
        });
      }
    },
    jump_now(jump_path) {
      // this.$router.push(jump_path);
      var url = `${location.origin}/#` + jump_path;
      window.open(url);
    },
    // 流水
    jump() {
      // return this.$message({
      // // message:"暂未开放！",
      // type: 'warning'
      // });
      // this.url='http://localhost:8081'
      var url = `${this.url}/#/?t=${sessionStorage.token}&from_url=${location.origin}`;
      window.open(url);
    },
    // 税务
    taxJump() {
      // return this.$message({
      //   message: "暂未开放！",
      //   type: 'warning'
      // });
      // this.tax='http://localhost:8082'
      var url = `${this.tax}/#/?t=${sessionStorage.token}&from_url=${location.origin}`;

      window.open(url);
    },
    outlogin() {
      this.$router.push("/Login");
    },
  },
};
</script>
<style lang="less" scoped>
#nav {
  .color_box {
    height: 405px;
    background: url(./img/banner.png) repeat center center;
    background-size: cover;

    > .img {
      // margin: 44px auto;
      // height: 108px;
      text-align: center;
      line-height: 100px;
      font-size: 44px;
      color: #ffffff;
      letter-spacing: 2px;

      > img {
        width: 175px;
        margin-right: 8px;
        height: 300px;
      }
    }
  }

  .bg2 {
    position: absolute;
    top: 405px;
    bottom: 0;
    left: 0;
    right: 0;
    // background: pink;
    background: url(./img/bannerbom.png) no-repeat;
    background-size: 100% 100%;

    // border: 1px solid red;
    > .menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      top: -75px;

      // margin-top: 17px;
      .item {
        // border: 1px solid red;
        width: 332px;
        height: 396px;
        background: #ffffff;
        border-radius: 16px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        position: relative;
        // box-shadow: 0 4px 8px 0 #c1cdd9;
        cursor: pointer;

        // .left {
        //   width: 77px;
        //   height: 64px;
        //   margin: 55px 18px 0 30px;
        //   img {
        //     width: 100%;
        //     height: 100%;
        //   }
        // }
        // .img{
        //   background: url(./img/shuiwu.png) no-repeat;
        .right {
          width: 332px;
          flex: 1;
          padding: 32px;
          padding-bottom: 0px;
          z-index: 999;

          .title {
            display: flex;
            justify-content: space-between;
            font-family: PingFangSC-Medium;
            font-size: 24px;
            color: #333333;
            font-weight: 500;
          }

          .desc {
            font-family: PingFangSC-Regular;
            font-size: 20px;
            color: #333333;
            font-weight: 400;
            margin-top: 3.5px;
            margin-bottom: 7px;
          }

          .desc1 {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #596178;
            line-height: 24px;
            font-weight: 400;
          }
        }

        .footer {
          // border: 1px solid red;
          // width: 150px;
          height: 60px;

          .quetely {
            //  border: 1px solid red;
            margin-left: 40px;
            font-size: 16px;
            // color: #1989fa;
            width: 92px;
            height: 32px;
            background: #3a85fd;
            border-radius: 4px;
            color: #ffffff;
            text-align: center;
            font-weight: 400;
            line-height: 30px;
          }
        }

        // }
        .img {
          position: absolute;
          bottom: 0;
          right: 0;
          // top: 100px;
        }
      }

      .item:hover {
        box-shadow: 0px 0px 15px 0px #00000061;
      }
    }
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 43px !important;
    padding-right: 53px !important;

    img {
      height: 38px;
    }
  }
}
</style>
